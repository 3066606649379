.header {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: aquamarine;
  position: sticky;
  position: -webkit-sticky;
  z-index: 100;
}

.header ul {
  list-style: none;
  display: flex;
  align-items: baseline;
}

.header li {
  padding: 0.5rem;
}