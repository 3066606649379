.container {
  justify-items: center;
  display:grid;
}

.form {
  margin-right: 16px;
  margin-top: 60px;
  height: 200px;
  width: 350px;
  position: relative;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.author {
  font-weight: 900;
  font-size: 48px;
  margin: 0;
}

.footer {
  height: 60px;
  position: absolute;
  bottom: 0;
}

.body {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize:none;


  width: 98%;
  margin-top: 3px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
}