.item {
  list-style: none;
  margin-top: 60px;
  height: 200px;
  width: 350px;
  position: relative;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.author {
  margin: 0px;
  font-weight: 900;
  font-size: 48px;
}

.body {
  margin-top: 3px;
  font-weight: 500;
}

.footer {
  height: 60px;
  position: absolute;
  bottom: 0;
}


.likeCounter {
  margin-top: 0px;
  margin-left: 5px;
  display: inline-block;
  font-weight: 500;
}
